import { AuthConfig, HttpInterceptorConfig } from '@auth0/auth0-angular'

// import config from '../../auth.config.json'

// const { domain, clientId, audience, apiUrl, ssrApiUrl, port } = config as {
//   domain: string
//   clientId: string
//   audience: string
//   apiUrl: string
//   ssrApiUrl: string
//   port: string
// }

const apiUrl = '/api/v1'
const ssrApiUrl = 'https://demo.proximilar.com/api/v1'

const httpInterceptor: HttpInterceptorConfig = {
  allowedList: [
    `${apiUrl}/user-info`,
    {
      uri: `${apiUrl}/*`,
      allowAnonymous: true,
    },
  ],
}

const auth: AuthConfig = {
  domain: 'proximilar-demo.us.auth0.com',
  clientId: 'o6rvooCYrYOcimfYGiLTkzIfDXtTnEZl',
  cacheLocation: 'localstorage',
  authorizationParams: {
    audience: 'https://demo.proximilar.com/api/v1',
    redirectUri: 'https://demo.proximilar.com/',
    redirect_uri: 'https://demo.proximilar.com/',
  },
}

export const ENV = {
  appVersion: require('../../package.json').version + '-dev',
  production: false,
  allowNgrxDebug: false,
  ssrApiUrl,
  apiUrl,
  auth,
  httpInterceptor,
  env: 'demo',
}
